import React from 'react'
import "./Programs.css"
import {programsData} from "../../data/programsData"
import RightArrow from "../../assets/rightArrow.png"

const Programs = () => {
      function whatsapp(){
        var name = document.getElementById("name").value;
        var email = document.getElementById("email").value;
        var phone = document.getElementById("phone").value;
        var age = document.getElementById("age").value;
        var plan = document.getElementById("plan").value;
        var message = document.getElementById("message").value;
         
        var url = "https://wa.me/+918766539293?text="
        +"*Name :* "+name+"%0a"
        +"*Email :* "+email+"%0a"
        +"*Contact No :* "+phone+"%0a"
        +"*Age :* "+age+"%0a"
        +"*Plan :* "+plan+"%0a"
        +"*Message :* "+message;
         
        window.open(url,'_blank').focus();
        }
  return (
    <div className="Programs" id="programs">
        <div className="programs-header">
            <span className='stroke-text'>Explore Our</span>
            <span className='stroke-text'>Programs</span>
            <span className='stroke-text'>to shape you</span>
        </div>
        <div className="programs-categories">
            {programsData.map((program)=>(
                <div className="category">
                    {program.image}
                    <span>{program.heading}</span>
                    <span>{program.details}</span>
                    <div className="join-now">
                        <span onClick={whatsapp}>Join Now</span>
                        <img src={RightArrow} alt="Right Arrow"/>
                    </div>
                </div>
            ))}
        </div>
    </div>

  )
}

export default Programs
